// // border
$radius: 4px;
$radius-block: 8px;

// //
$border-color: #e8e8e8;

$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;

// color
$primary-color: #499BE9;
// 中性色
$text-header-color: #222831; // 标题色
$text-color: #666; // 主要文字
$text-color-secondary: #999; // 次要文字
$text-color-disable-and-placeholder: #b7b7b7; // 预输入/禁用文字
$text-color-black: #000;
$status-color-online: #52C41A; // 现在状态
$status-color-offline: #B7B7B7; // 离线状态
$amount-color: #FA541C; // 金额颜色

//bg
$bg-normal: #F0F2F5;
$bg-white: #fff;

@mixin default-font-size-and-height($size) {
  $fix-height: 4;
  font-size: $size + px;
  line-height: $size + $fix-height + px;
}

@mixin multi-line ($num) {
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: $num;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@mixin single-line {
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
  -moz-text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin page-content-block {
  border-radius: 8px;
  background-color: $bg-white;
  padding: 24px;
}

$resume-width: 820px;
$resume-height: 1160px;
// 简历pdf的宽高比
$resume-aspect-ratio: 820 / 1160;
$content-width: 1220px;
*,
html {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html {
  -webkit-text-size-adjust: 100%;
}

#root {
  height: auto;
  overflow: visible;
}

body {
  @include default-font-size-and-height(14);
  #root {
    width: 100vw;
    height: 100vh;
    min-width: 1200px;
    background: $bg-normal;
  }
}

// /* 滚动条整体样式 */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #c2c2c2;
}
